import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from '../components/carousel'
import SEO from '../components/seo'

export const query = graphql`
query($slug: String!){
    product: strapiProduct(slug: {eq: $slug}) {
      slug
      name
      id
      category {
        name
      }
      bodyDescription
      images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      cover {
        localFile {
            childImageSharp {
              gatsbyImageData
            }
        }
      }
    }
  }
`

export default function productTemplate({ data }) {
  const product = data.product
  return (
    <Layout>
      <SEO
        title={product.name}
      />
      <section>
        <div class="relative mx-auto container">
          <div class="flex flex-col lg:flex-row gap-6 lg:gap-12 w-full items-center">
            <div className="w-full lg:w-2/3">
              {product.images ?
                <Carousel images={product.images} />
                :
                <GatsbyImage image={getImage(product?.cover?.localFile)} />
              }
            </div>

            <div class="w-full lg:w-1/3">
              <div class="flex justify-between">
                <div class="mb-4">
                  <h1 class="text-4xl font-playFair">{product.name}</h1>
                  <span class="my-6 text-primary eyebrow-left eyebrow-right">{product.category.name}</span>
                </div>
              </div>
              <div class="max-w-none">
                <p>{product.bodyDescription}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
